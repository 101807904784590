import React from 'react';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { TitleAndIngessImageCtaHero } from './TitleAndIngressImageCtaHero';
import { TitleAndIngressHero } from './TitleAndIngressHero';
import { TitleAndWideImageHero } from './TitleAndWideImageHero';
import { CategoryAppHeroType } from '../../../../../../types/CategoryAppType';
import { LoginHero } from './LoginHero/LoginHero';
import { TitleHero } from './TitleHero';

interface HeroBlockProps {
  block: CategoryAppHeroType;
  breadCrumbs?: BreadcrumbData[];
  themeType?: string;
}

export const Hero = ({ block, breadCrumbs, themeType }: HeroBlockProps) => {
  if (!block) return null;
  switch (block.heroVariant) {
    case 'heroWithTitleIngressSmallImageAndCta':
      return <TitleAndIngessImageCtaHero hero={block} breadCrumbs={breadCrumbs} />;
    case 'heroWithTitleAndIngress':
      return <TitleAndIngressHero hero={block} breadCrumbs={breadCrumbs} />;
    case 'heroWithTitleAndWideImage':
      return <TitleAndWideImageHero hero={block} breadCrumbs={breadCrumbs} themeType={themeType} />;
    case 'heroWithTitleIngressAndIllustration':
      // TODO: this should probably be fixed by Magne
      return null;
    case 'heroWithTitleImageBodyAndLogin':
      return <LoginHero block={block} />;
    case 'heroWithTitle':
      return <TitleHero hero={block} breadCrumbs={breadCrumbs} />;
    default:
      return null;
  }
};
