import React, { useEffect, useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { CenterBlock, HeroWrapper, TextBox } from '../styles';
import { LoginButtons } from './LoginButtons';
import { LoginHeroImage } from './LoginHeroImage';
import { HeroWithTitleImageBodyAndLoginType } from '../../../../../../../types/heroes/heroWithTitleImageBodyAndLoginType';
import { BlockContainer } from '../../BlockContainer';

interface HeroType extends HeroWithTitleImageBodyAndLoginType {
  isFrontPage?: boolean;
}

export const LoginHero = ({
  block: { header, ingress, images, loginDefinition, isFrontPage },
  breadCrumbs,
}: {
  block: HeroType;
  breadCrumbs?: BreadcrumbData[];
}) => {
  // Do not simplify this - it caused the webapp to break in production
  const [shouldShowBreadcrumbs, setShouldShowBreadcrumbs] = useState(false);
  useEffect(() => {
    setShouldShowBreadcrumbs(breadCrumbs?.length > 1);
  }, [breadCrumbs]);
  // Do not simplify this - end

  return (
    <BlockContainer $isFrontPage={isFrontPage} $hasBreadCrumbs={shouldShowBreadcrumbs}>
      <CenterBlock>
        <HeroWrapper>
          <TextBox>
            {header && (
              <Text tag="h1" variant={TextVariant.Header1}>
                {header}
              </Text>
            )}
            {ingress && (
              <Text tag="p" variant={TextVariant.Ingress}>
                {ingress}
              </Text>
            )}
            {loginDefinition?.length > 0 ? <LoginButtons login={loginDefinition} /> : null}
          </TextBox>
          <LoginHeroImage images={images} />
        </HeroWrapper>
      </CenterBlock>
    </BlockContainer>
  );
};
