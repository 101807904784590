import React from 'react';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { CloudinaryImageType } from '../../../../../../../types/blockTypes';
import { useCloudinary } from '../../../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../../../hooks/useDevicePixelRatio';
import { ImageBox } from '../styles';
import { LoginHeroImageType } from '../../../../../../../types/heroes/heroWithTitleImageBodyAndLoginType';

export const LoginHeroImage = ({ images }: { images: LoginHeroImageType[] }) => {
  if (!images) return null;
  for (const image of images) {
    if (image.type === 'cloudinaryImage') {
      const cloudinaryImage = <CloudinaryImage image={image} key={image.key} />;
      if (cloudinaryImage) {
        return cloudinaryImage;
      }
    }
  }

  return null;
};

const CloudinaryImage = ({ image }: { image: CloudinaryImageType }) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const cldImage = image.publicId
    ? cld.image(image.publicId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto')
    : undefined;
  return cldImage ? (
    <ImageBox>
      <AdvancedImage
        alt={image?.altText}
        cldImg={cldImage}
        plugins={[
          lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
          responsive({ steps: 200 }),
          placeholder({ mode: 'blur' }),
        ]}
      />
    </ImageBox>
  ) : null;
};
