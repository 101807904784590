import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { spacing } from '@naf/theme';
import { Button } from '@naf/button';
import { ColorVariant } from '../../../../../../../types/featuredBenefitBlockType';
import { useGTMDataLayer } from '../../../../../hooks/useGTMDataLayer';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';
import { VippsLoginButton } from '../../../../VippsLoginButton';
import { LoginButtonDefinition } from '../../../../../../../types/heroes/heroWithTitleImageBodyAndLoginType';

const LoginButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing.space16};
`;

export const LoginButtons = ({ login }: { login: LoginButtonDefinition[] }) => (
  <LoginButtonsWrapper>
    {login.map((definition) => (
      <LoginButtonMapper definition={definition} key={definition.key} />
    ))}
  </LoginButtonsWrapper>
);

const LoginButtonMapper = ({ definition }: { definition: LoginButtonDefinition }) => {
  const location = useLocation();

  const { returnTo, buttonText, colorVariant } = definition;
  const { linkType, ctaInternalLink, ctaExternalLink } = returnTo;

  const ctaUrl = useDocumentUrlWithNode(ctaInternalLink?.referenceComponent);

  const getReturnUrl = () => {
    const currentUrl = `${location.pathname}${location.search}`;
    switch (linkType) {
      case '':
        return currentUrl;
      case 'internalLink':
        return ctaUrl || currentUrl;
      case 'externalLink':
        return ctaExternalLink?.link || currentUrl;
      default:
        return currentUrl;
    }
  };

  switch (definition.type) {
    case 'vippsLoginButton':
      return <VippsLoginButton returnUrl={getReturnUrl()} isStretched />;
    case 'defaultLoginButton':
      return (
        <DefaultLoginButton
          returnUrl={getReturnUrl()}
          buttonText={buttonText}
          colorVariant={colorVariant}
          key={definition.key}
        />
      );
    default:
      return null;
  }
};

const DefaultLoginButton = ({
  returnUrl,
  buttonText,
  colorVariant,
}: {
  returnUrl: string;
  buttonText?: string;
  colorVariant?: ColorVariant;
}) => {
  const { loginWithRedirect } = useAuth0();
  const dataLayer = useGTMDataLayer();
  const location = useLocation();

  const handleTracking = () => {
    dataLayer?.push({
      event: 'cta_click',
      cta_text: 'Logg inn',
      cta_location: location.pathname,
    });
  };

  return (
    <Button
      size="large"
      variant={colorVariant ?? 'signature'}
      onClick={() => {
        handleTracking();
        loginWithRedirect({ appState: { returnTo: returnUrl } });
      }}
    >
      {buttonText ?? 'Logg inn'}
    </Button>
  );
};
