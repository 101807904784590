import React, { useEffect, useState } from 'react';
import { Grid } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { BlockContainer } from '../BlockContainer';
import { CenterBlock, HeroWrapper3 } from './styles';
import BreadCrumb from '../../../breadcrumb/BreadCrumb';
import { BreadCrumbContainer } from './BreadCrumbContainer';
import { HeroWithTitleType } from '../../../../../../types/heroes/heroWithTitleType';

interface FrontPageHeroType extends HeroWithTitleType {
  isFrontPage?: boolean;
}

export const TitleHero = ({
  hero: { header, isFrontPage },
  breadCrumbs,
}: {
  hero: FrontPageHeroType;
  breadCrumbs?: BreadcrumbData[];
}) => {
  // Do not simplify this - it caused the webapp to break in production
  const [shouldShowBreadcrumbs, setShouldShowBreadcrumbs] = useState(false);
  useEffect(() => {
    setShouldShowBreadcrumbs(breadCrumbs?.length > 1);
  }, [breadCrumbs]);
  // Do not simplify this - end
  return (
    <>
      {shouldShowBreadcrumbs && (
        <BreadCrumbContainer>
          <BreadCrumb />
        </BreadCrumbContainer>
      )}
      <BlockContainer $isFrontPage={isFrontPage} $hasBreadCrumbs={shouldShowBreadcrumbs}>
        <CenterBlock>
          <Grid>
            <HeroWrapper3 s="12" m="12" l="12" xl="12">
              {header && (
                <Text tag="h1" variant={TextVariant.Header1}>
                  {header}
                </Text>
              )}
            </HeroWrapper3>
          </Grid>
        </CenterBlock>
      </BlockContainer>
    </>
  );
};
